import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Label } from '../ui/label';
import { ErrorFeedback } from '../ui/error-feedback';
import { MayarAlertBase } from '../ui/mayar-alert-base';
import { Input } from '../ui/input';
import {
  aggregateArrayOfObject,
  defaultChannelConfig,
  validator,
  temporaryPaymentDisabled,
  checkPaymentConfig,
} from '../../utils';
import Icon from '../icon';
import { Card } from '../ui/card';

const widthCollapseLogo = {
  OVO: '40',
  DANA: '52',
  SHOPEEPAY: '40',
  LINKAJA: '18',
  JENIUSPAY: '25',
  ALFAMART: '45',
  INDOMARET: '45',
  AKULAKU: '25',
};

const bankCollapseList = [
  { name: 'bersama', width: '32' },
  { name: 'prima', width: '28' },
  { name: 'alto', width: '30' },
];

const getWidthLogo = (code, widthData) => {
  const width = widthData[code] || '45';
  return width;
};

export const SelectPayment = ({
  payChannelConfig,
  formState,
  amount,
  onValueChange,
  isVisible,
}) => {
  const { t } = useTranslation();
  const [toggleSlideEvent, setToggleSlideEvent] = useState(null);
  // const basePath = process.env.NEXT_PUBLIC_BASE_PATH
  const basePath = null
  const { register, formState: formUtil } = formState || {};
  const { errors } = formUtil;
  const configList = checkPaymentConfig(payChannelConfig?.config, defaultChannelConfig);
  const channelConfig = aggregateArrayOfObject(configList, 'type');
  const isAkulakuApproved = payChannelConfig?.isAkulakuApproved;
  const isCreditCardApproved = payChannelConfig?.isCreditCardApproved;
  let isAnyActiveChannelType = false

  const createToggleEvent = (object) => {
    const temp = {};
    object.forEach((objKey) => {
      temp[objKey.type] = { value: 0, toggle: false };
    });

    return temp;
  };

  const handleToggleEvent = (objKeys) => {
    const temp = { ...toggleSlideEvent };
    temp[objKeys] = {
      value: Date.now(),
      toggle: !temp[objKeys].toggle,
    };

    setToggleSlideEvent(temp);
  };

  useEffect(() => {
    setToggleSlideEvent(createToggleEvent(channelConfig));
  }, [payChannelConfig, isVisible]);

  return (
    <div>
      {isVisible && (
        <>
          {' '}
          {!toggleSlideEvent && (
            <div className="my-[20px] flex flex-wrap mx-[15px] w-full">
              <strong className="flex flex-grow text-secondary text-xl font-bold text-center px-[25px] m-0 loading-text-ellipsis">
                {t('checkout.loadPayment')}
              </strong>
            </div>
          )}
          <div className="mt-[15px] p-0 w-full text-[15px] font-[500]">
            <h5 className="mb-[15px] w-full text-center">
              <strong>{t('form.paymentMethod')}</strong>
            </h5>

            <Accordion type="multiple" collapsible>
              {channelConfig.map((config) => {
                const activePayments = config.values.filter(
                  (item) => item.status && !temporaryPaymentDisabled.includes(item.code),
                );
  
                if (
                  temporaryPaymentDisabled.includes(config.code) ||
                  (config.type === 'qris' && (amount < 1 || amount > 10_000_000)) ||
                  (config.type === 'ewallet' && (amount < 100 || amount > 10_000_000)) ||
                  (config.type === 'va' && amount < 10_000) ||
                  (config.type === 'retail' && (amount < 10_000 || amount > 5_000_000)) ||
                  (config.type === 'paylater' && (amount < 10_000 || amount > 25_000_000)) ||
                  (config.type === 'card' && amount < 5000) ||
                  activePayments.length <= 0
                ) {
                  return null;
                }
  
                if (
                  (config.type === 'paylater' && !isAkulakuApproved) ||
                  (config.type === 'card' && !isCreditCardApproved)
                ) {
                  return null;
                }
  
                const getPaymentTypeTitle = (type) => {
                  switch (type) {
                    case 'va':
                      return 'Transfer Bank (VA)';
                    case 'ewallet':
                      return 'E-Wallet';
                    case 'card':
                      return 'Kartu Kredit / Debit';
                    case 'retail':
                      return 'Mini Market';
                    default:
                      return type;
                  }
                };
  
                const createPaymentPath = (code, imgUrl, name) => {
                  let path;
  
                  switch (config.type) {
                    case 'qris':
                      path = 'qris';
                      break;
                    case 'card':
                      path = 'creditcard';
                      break;
                    case 'va':
                      path = `va/${code}`;
                      break;
                    case 'ewallet':
                      path = `ewallet/${code}`;
                      break;
                    case 'retail':
                      path = `outlet/${code}`;
                      break;
                    case 'paylater':
                      path = `paylater/${code}`;
                      break;
                    default:
                      break;
                  }
  
                  if ((imgUrl && imgUrl?.includes('bank-lain')) || name === 'Bank Lainnya') {
                    path = `va/OTHER`;
                  }
  
                  return path;
                };
  
                const getIMGWidth = ({ code, img }) => {
                  let width = '80';
  
                  switch (code) {
                    case 'SHOPEEPAY':
                    case 'OVO':
                    case 'BNI':
                      width = '70';
                      break;
                    case 'LINKAJA':
                    case 'JENIUSPAY':
                      width = '35';
                      break;
                    case 'AKULAKU':
                      width = '30';
                      break;
                    case 'FLIP':
                      width = '25';
                      break;
                    case 'BJB':
                      width = '50';
                      break;
                    default:
                      break;
                  }
  
                  if (img.includes('bank-lain')) {
                    width = '40';
                  }
  
                  return width;
                };
  
                isAnyActiveChannelType = true;
                return (
                  <AccordionItem value={config.type} key={config.type} className='m-0 border-0'>
                    <AccordionTrigger className='m-0 border-0 mb-[10px] p-0' noArrow={true}>
                      <Button
                        className="py-[15.5px] px-[15px] text-secondary flex items-center w-full justify-between checkout-select-payment text-[12px] uppercase rounded-sm"
                        size="lg"
                        type='button'
                        variant="light"
                        onClick={() => handleToggleEvent(config.type)}
                      >
                        <span className="flex items-center justify-between w-[100%]">
                          {(config.type === 'ewallet' ||
                            config.type === 'retail' ||
                            config.type === 'paylater') && (
                            <span className='flex gap-2'>
                              {activePayments.map(({ name, img, code }) => {
                                if (code === 'ALFAMART' && amount > 2_500_000) return;
                                if (code === 'JENIUSPAY' && amount < 1_000) return;

                                return (
                                  <img
                                    key={code}
                                    alt={`Logo ${name}`}
                                    src={img.replace(/.png/, '.svg')}
                                    width={getWidthLogo(code, widthCollapseLogo)}
                                    // className="mr-5"
                                  />
                                );
                              })}
                            </span>
                          )}
                          {(config.type === 'qris' || config.type === 'card') && (
                            <span>
                              <img
                                alt="Logo"
                                src={
                                  config.type === 'qris'
                                    ? `${basePath ? basePath : ""}/QrisBI.svg`
                                    : activePayments[0]?.img.replace(/.png/, '.svg')
                                }
                                width={config.type === 'qris' ? '110' : '65'}
                                className="just-mobile"
                              />
                              <img
                                alt="Logo"
                                src={
                                  config.type === 'qris'
                                    ? `${basePath ? basePath : ""}/qris.svg`
                                    : activePayments[0]?.img.replace(/.png/, '.svg')
                                }
                                width={config.type === 'qris' ? '160' : '65'}
                                className="just-tablet"
                              />
                            </span>
                          )}
                          {config.type === 'va' && (
                            <span className='flex gap-2'>
                              {bankCollapseList.map(({ name, width }, index) => (
                                <img
                                  key={index}
                                  alt={`Logo ATM ${name}`}
                                  src={`${basePath ? basePath : ""}/atm-${name}.svg`}
                                  width={width}
                                  // className="mr-5"
                                />
                              ))}
                            </span>
                          )}
                          <span className="mr-[3px] text-[13px] font-[600] font-open-sans">
                            {config.type === 'card'
                              ? t('paymentChannel.credit')
                              : getPaymentTypeTitle(config.type)}
                          </span>
                        </span>
                        <Icon
                          name={`chevron-${
                            toggleSlideEvent?.[config.type]?.toggle ? 'down' : 'right'
                          }`}
                          className="rui-icon-collapse"
                        />
                      </Button>
                    </AccordionTrigger>
                    <AccordionContent className='p-0 mb-[10px]'>
                      <div className="mt-0 w-full flex flex-col pl-0 mb-0">
                        {activePayments.map((item, index) => {
                          if (item.code === 'BRI' && amount > 10_000_000) return;
                          if (item.code === 'ALFAMART' && amount > 2_500_000) return;
                          if (item.code === 'JENIUSPAY' && amount < 1_000) return;

                          return (
                            <Label
                              for={`${item.type}-${item.code}`}
                              key={index}
                              check
                              className="cursor-pointer mb-0"
                            >
                              <Card className="flex mb-[10px] justify-between items-center select-payment-item sm:py-[0.2rem] py-[0.6rem] px-[1.25rem] shadow-none text-[15px] sm:text-[13px] rounded-sm text-secondary font-open-sans">
                                <div className='flex items-center'>
                                  <Input
                                    id={`${item.type}-${item.code}`}
                                    name="payment"
                                    type="radio"
                                    value={createPaymentPath(item.code, item.img, item.name)}
                                    {...register('payment', { required: validator.required })}
                                    invalid={Boolean(errors.payment)}
                                    className="mt-0 w-[13px]"
                                    onClick={({ currentTarget }) =>
                                      onValueChange({
                                        ...item,
                                        path: currentTarget.value,
                                        imgWidth: getIMGWidth(item),
                                      })
                                    }
                                  />

                                  <span className="text-center ml-[10px]">
                                    {item.name.includes('Flip')
                                      ? item.name.replace(/Bank Transfer\s/, '')
                                      : item.name.replace(/Retail\s|EWallet\s|\sTransfer/, '')}{' '}
                                    <br />
                                  </span>
                                </div>
                                
                                {item.type === 'qris' ? (
                                  <span>
                                    <img
                                      alt="logo"
                                      src={`${basePath ? basePath : ""}/QrisBI.svg`}
                                      width="130"
                                      className="just-mobile"
                                    />
                                    <img
                                      alt="logo"
                                      src={`${basePath ? basePath : ""}/qris.svg`}
                                      width="190"
                                      className="just-tablet"
                                    />
                                  </span>
                                ) : (
                                  <span className="ml-[20px]">
                                    <img
                                      alt={`Logo ${item.name}`}
                                      src={item.img.replace(/.png/, '.svg')}
                                      width={getIMGWidth(item)}
                                    />
                                  </span>
                                )}
                              </Card>
                            </Label>
                          );
                        })}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                )
              })}
            </Accordion>
            {isAnyActiveChannelType === false && <MayarAlertBase variant='danger'>Tidak ada metode pembayaran yang tersedia untuk nominal pembayaran</MayarAlertBase>}
            <div className={Boolean(errors.payment) ? 'is-invalid' : ''} />
            <ErrorFeedback id="payment-hint">{errors?.payment && t('checkout.paymentWarning')}</ErrorFeedback>
          </div>
        </>
      )}
    </div>
  );
};

SelectPayment.defaultProps = {
  isVisible: true,
};

SelectPayment.propTypes = {
  payChannelConfig: PropTypes.object,
  amount: PropTypes.number,
  formState: PropTypes.object,
  isVisible: PropTypes.bool,
  onValueChange: PropTypes.func,
};
